import { UserData } from "./UserData";

/**类型1后台上下分2购买商城3充值4提现5签到送钻石6新注册送钻石7分享注册送钻石 */
export enum BalanceType{
    /** 后台充值 */
    admin = 1,
    /** 分享得生命 */
    shared,
    /** 看广告得生命 */
    advert,
    /** 注册成功的生命 */
    register,
    /** 玩游戏减生命值 */
    game
}
export interface BalanceData{
    id:number,
    userId:number,
    user?:UserData,
    money:number,
    balance:number,
    type:BalanceType,
    memo:string,
    source_id:number,
    ctime:Date,
}
export const BalanceOptions = [
    {label:"全部",value:'0'},
    {label:"平台充值",value:BalanceType.admin},
    {label:"广告奖励",value:BalanceType.advert},
    {label:"游戏消耗",value:BalanceType.game},
    {label:"注册赠送",value:BalanceType.register},
    {label:"分享赠送",value:BalanceType.shared},
]