
  import { defineComponent } from "vue";
  import { client } from '@/client';
import {Search } from "@element-plus/icons-vue";
  import { ElMessage } from "element-plus";
import { BalanceData, BalanceOptions, BalanceType } from "@/shared/types/BalanceData";
import { UserData } from "@/shared/types/UserData";
  export default defineComponent({
    name: "tag-edit",
    setup() {
      return {
        Search,
      };
    },
  
    computed: {
      user() {
        return this.params.user?this.params.user:{} as UserData;
      }
    },
    data: () => {
      return {
        params: {} as BalanceData,
        roles: BalanceOptions,
        roleSelected:['0'] as any[],
  
        paramsRules: {
        },
      };
    },
    async created() {
      let id = this.$route.params.id as string;
      this.params.id = parseInt(id);
      await this.detail();
    },
    methods: {
        handleChange(){
            this.params.type = this.roleSelected[0];
            console.error(this.roleSelected[0]);
        },
      // 文章详情
      async detail() {
        try {
          let res = await client.callApi("balance/Detail",{id:this.params.id});
          if (res.isSucc) {
            this.params = {...res.res};
            this.roleSelected[0] = this.params.type;
          }
        } catch (error) {
          console.error(error);
        }
      },
      //新增
      async update() {
        let res = await client.callApi("balance/Update",this.params);
        if (res.isSucc) {
            ElMessage({
                message: "更新成功^_^",
                type: "success",
            });
            this.$router.go(-1);
        }else{
            ElMessage({
                message: res.err.message,
                type: "error",
            });
        }
      },
      submit(formName: string) {
        let refs:any = this.$refs[formName];
  
        refs.validate((valid: any) => {
          if (valid) {
              this.update();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    },
  });
  