import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-10 ml-10 mb-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "params",
      model: _ctx.params,
      rules: _ctx.paramsRules,
      "label-width": "84px",
      class: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_form_item, { label: "ID" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.id) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "金额",
            prop: "money"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.money,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.money) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "余额",
            prop: "balance"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.balance,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.balance) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "描述",
            prop: "memo"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.memo,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.memo) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "资源id",
            prop: "source_id"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.source_id,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.params.source_id) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "用户id",
            prop: "userId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.userId,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.params.userId) = $event)),
                style: {"width":"200px"}
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "类型",
            prop: "type"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_cascader, {
                class: "w-auto ml-5",
                "show-all-levels": false,
                modelValue: _ctx.roleSelected,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.roleSelected) = $event)),
                options: _ctx.roles
              }, null, 8, ["modelValue", "options"])
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.submit('params')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("保存")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}